import apiWrapper from '@/utils/apiWrapper';

export const getRegistrationInfo = (eventCode, raceCode, relayPosition = null) => {
  const apiUrl = new URL('/Register/ApiRegistration', location);

  apiUrl.searchParams.set('eventCode', eventCode);
  apiUrl.searchParams.set('raceCode', raceCode);

  if (relayPosition) {
    apiUrl.searchParams.set('relayPosition', relayPosition);
  }

  return apiWrapper.getWithAuth(apiUrl.toString());
};
