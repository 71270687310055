import responseObject from '@/utils/responseObject.js';

export const removeProductFromCart = (registrationId, cartItemId) => {
  return fetch('/Register/ApiRemoveProductFromCart', {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      CartItemId: cartItemId,
      RegistrationId: registrationId,
    }),
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return responseObject(data);
    })
    .catch((error) => responseObject(null, error));
};
