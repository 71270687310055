export const getCurrentUser = () => {
  return fetch('/Account/getCurrent')
    .then((res) => res.json())
    .then((data) => data)
    .catch((e) => {
      throw Error(e);
    });
};

export const getProfileUserInfo = () => {
  return fetch('/Profile/GetUserInfo')
    .then((res) => res.json())
    .then((data) => data)
    .catch((e) => {
      throw Error(e);
    });
};