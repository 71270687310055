import responseObject from '@/utils/responseObject.js';

/**
 *
 * @param {import('./types').Product} productInfo
 * @returns
 */
export const addProductToCart = (productInfo) => {
  return fetch('/Register/ApiAddProductToCart', {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(productInfo),
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return responseObject(data);
    })
    .catch((error) => responseObject(null, error));
};
