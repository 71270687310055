import apiWrapper from '@/utils/apiWrapper.js';
import i18Instance from '@/i18n';

/**
 * @description Получение списка профессий
 * @param {string} lang
 * @returns {Array}
 */
export const getOccupations = () => {
  return apiWrapper.get(`/Register/ApiGetOccupations?language=${i18Instance.global.locale.value}`);
};

