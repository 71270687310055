function setWithExpiry(key, seconds) {
  const now = new Date();

  localStorage.setItem(key, now.getTime() + (seconds * 1000));
}

function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const now = new Date();
  if (now.getTime() > itemStr) {
    localStorage.removeItem(key);
    return null;
  }
  return itemStr;
}

export {
  setWithExpiry,
  getWithExpiry
};