import responseObject from '@/utils/responseObject.js';

/**
 *
 * @param {import('./types').InsuranceInfo} insuranceInfo
 * @returns {Object}
 */
export const getPolicyRequest = (insuranceInfo) => {
  return fetch('/Register/ApiPolicyRequest', {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(insuranceInfo),
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return responseObject(data);
    })
    .catch((error) => responseObject(null, error));
};
