<template>
  <component :is="layoutComponent">
    <router-view />
  </component>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { watch, shallowRef, defineAsyncComponent, onMounted } from 'vue';
import { useUser, useVariablesStore } from './store';
import metric from '@/libs/metric.js';

const route = useRoute();
const layoutComponent = shallowRef();
const userStore = useUser();
const varsStore = useVariablesStore();

watch(
  () => route.meta.layout ?? 'default',
  layout => {
    layoutComponent.value = defineAsyncComponent(() => import(`@/layouts/${layout}.vue`));
    document.documentElement.setAttribute('data-layout', layout);
  },
  { immediate: true },
);

onMounted(() => {
  try {
    userStore.getUser().then((user) => {
      metric('params', {
        UserStatus: {
          SignedIn: user.isAuthorized,
        }
      });
    });
    varsStore.setTenantInfo();
    varsStore.setLiveCount();
  } catch (error) {
    console.error(error);
  }
});
</script>

<style lang="scss">
@use './assets/styles/main.scss';
</style>
