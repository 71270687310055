import responseObject from './responseObject.js';

export const handler = async (apiPath, options = {}) => (
  fetch(apiPath, options)
    .then((res) => res.json())
    .then((data) => responseObject(data))
    .catch((error) => responseObject(null, error))
);

export const handlerWithAuth = async (apiPath, options = {}) => {
  const response = await fetch(apiPath, options);

  const returnUrl = encodeURIComponent(`${window.location.pathname}${window.location.search ? '?' + window.location.search : ''}`);

  if (response.type === 'opaqueredirect') {
    window.location = `/auth/sign-in?returnUrl=${returnUrl}`;
    return;
  }

  try {
    const data = await response.json();
    return responseObject(data);
  } catch (error) {
    return responseObject(null, error);
  }
};

export default {
  get: (apiPath, credentials = 'include') => handler(apiPath, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials,
  }),
  post: (apiPath, body = {}, headers = {}, credentials = 'include') => handler(apiPath, {
    method: 'POST',
    credentials,
    body: JSON.stringify(body),
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      ...headers
    },
  }),
  getWithAuth: (apiPath) => handlerWithAuth(apiPath, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    redirect: 'manual',
    credentials: 'include',
  }),
  postWithAuth: (apiPath, body = {}, headers = {}) => handlerWithAuth(apiPath, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(body),
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      ...headers
    },
  }),
};

