import isEmptyObject from './isEmptyObject.js';

/**
 * @description Проверяет, есть ли значение по пути в объекте
 * @param {Object} data
 * @param {string} path
 */
export default (data, path) => {
  const value = path
    .split('.')
    .reduce((prev, key) => (prev[key] || {}), (data || {}));

  return isEmptyObject(value) ? null : value;
};
