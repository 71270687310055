import responseObject from '@/utils/responseObject.js';

/**
 * @description Вызывается на переходе в шаг оплаты из корзины для проверки актуальности. Показывается ошибка "изменилась сумма покупок", обновите страницу
 */
export const getCartInfo = (registrationId) => {
  return fetch('/Register/Cart', {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      RegistrationId: registrationId,
    }),
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return responseObject(data);
    })
    .catch((error) => responseObject(null, error));
};
