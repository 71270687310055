import { createI18n } from 'vue-i18n';

function findLocales(context) {
  const messages = {};

  context.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = context(key);
    }
  });

  return messages;
}

const i18Instance = createI18n({
  legacy: false,
  locale: 'ru',
  globalInjection: true,
  fallbackLocale: 'ru',
  messages: findLocales(require.context('./locales', true, /([A-Za-z0-9-_]+)\./i)),
  pluralRules: {
    ru: (val) => {
      const cases = [2, 0, 1, 1, 1, 2];
      return (val % 100 > 4 && val % 100 < 20) ? 2 : cases[(val % 10 < 5) ? val % 10 : 5];
    },
  },
});

export default i18Instance;
