// SimpleChoice, DropDownChoice, TextInput, UploadImage, CheckBox
import { userStorageInfo } from '@/composables/useUserInfo.js';
import { defineAsyncComponent, ref, computed } from 'vue';

const storageAnswers = computed(() => userStorageInfo.value.answers || []);

const Options = defineAsyncComponent(() => import('@/components/fields/options/Options.vue'));
const Text = defineAsyncComponent(() => import('@/components/fields/text/Text.vue'));
const Choice = defineAsyncComponent(() => import('@/components/fields/choice/Choice.vue'));
const ChoiceMultiple = defineAsyncComponent(() => import('@/components/fields/choice-multiple/ChoiceMultiple.vue'));
const UploadImage = defineAsyncComponent(() => import('@/pages/register/steps/questionnaire/parts/upload-image/UploadImage.vue'));
const Checkbox = defineAsyncComponent(() => import('@/components/fields/checkbox/Checkbox.vue'));

export default (answers = []) => ({
  DropDownChoice: (question) => {
    const answerFromStorage = storageAnswers.value.find((answer) => {
      const isCodeEqual = answer.QuestionCode === question.Code;
      if (question.productId) {
        return (answer.productId === question.productId) && isCodeEqual;
      }

      return isCodeEqual;
    })?.SelectedOptionsCodes?.[0];
    const answer = answers.find((answer) => answer.QuestionCode === question.Code)?.SelectedOptionsCodes?.[0];
    const finalAnswer = answerFromStorage || answer;

    return {
      value: ref(finalAnswer),
      isValid: ref(!!finalAnswer),
      component: Options,
      code: question.Code,
      type: question.Type,
      required: question.AnswerRequired,
      props: {
        placeholder: question.Text,
        title: question.Text,
        options: question.Options
          .filter((item) => item.IsActive)
          .map((item) => {
            // TODOME если англ язык - то Code, если русский, то Text
            return {
              id: item.Text,
              name: item.Text,
            };
          }),
        errorText: question.AnswerRequiredValidationError,
      },
      createApiAnswer() {
        return {
          QuestionCode: this.code,
          SelectedOptionsCodes: [this.value.name],
          Type: this.type,
          error: this.props.errorText,
          isValid: this.isValid,
        };
      },
    };
  },
  TextInput: (question) => {
    const answerFromStorage = storageAnswers.value.find((answer) => answer.QuestionCode === question.Code)?.Text;
    const answer = answers.find((answer) => answer.QuestionCode === question.Code)?.Text;
    const finalAnswer = answerFromStorage || answer;

    return {
      value: ref(finalAnswer),
      isValid: ref(!!finalAnswer),
      component: Text,
      type: question.Type,
      code: question.Code,
      required: question.AnswerRequired,
      props: {
        minLength: question.MinLength,
        maxLength: question.MaxLength,
        minLengthErrorText: question.LengthValidationError,
        placeholder: question.Text,
        title: question.Text,
        errorText: question.AnswerRequiredValidationError,
      },
      createApiAnswer() {
        return {
          QuestionCode: this.code,
          Text: this.value,
          Type: this.type,
          error: this.props.errorText,
          isValid: this.isValid,
        };
      },
    };
  },
  SimpleChoice: (question) => {
    const isMultipe = question.MultipleChoice;
    const answerFromStorage = storageAnswers.value.find((answer) => answer.QuestionCode === question.Code)?.SelectedOptionsCodes;
    const answer = answers.find((answer) => answer.QuestionCode === question.Code)?.SelectedOptionsCodes;
    const finalAnswer = answerFromStorage || answer;

    return {
      component: isMultipe ? ChoiceMultiple : Choice,
      code: question.Code,
      value: ref(isMultipe ? finalAnswer : finalAnswer?.[0]),
      type: question.Type,
      isValid: ref(!!finalAnswer),
      required: question.AnswerRequired,
      props: {
        placeholder: question.Text,
        title: question.Text,
        items: question.Options
          .filter((item) => item.IsActive)
          .map((item) => ({
            label: item.Text,
            value: item.Text,
          })),
        errorText: question.AnswerRequiredValidationError,
      },
      createApiAnswer() {
        const value = question.MultipleChoice && Array.isArray(this.value) ? [...this.value] : [this.value];

        return {
          QuestionCode: this.code,
          SelectedOptionsCodes: value,
          Type: this.type,
          error: this.props.errorText,
          isValid: this.isValid,
        };
      },
    };
  },
  CheckBox: (question) => {
    const answerFromStorage = storageAnswers.value.find((answer) => answer.QuestionCode === question.Code)?.IsChecked;
    const answer = answers.find((answer) => answer.QuestionCode === question.Code)?.IsChecked;
    const finalAnswer = answerFromStorage || answer;

    return {
      component: Checkbox,
      code: question.Code,
      type: question.Type,
      value: ref(finalAnswer),
      isValid: ref(!!finalAnswer),
      required: question.AnswerRequired,
      props: {
        description: question.Description,
        title: question.Text,
        errorText: question.AnswerRequiredValidationError,
      },
      createApiAnswer() {
        return {
          QuestionCode: this.code,
          IsChecked: this.value,
          Type: this.type,
          error: this.props.errorText,
          isValid: this.isValid,
        };
      },
    };
  },
  UploadImage: (question) => {
    const answerFromStorage = storageAnswers.value.find((answer) => answer.QuestionCode === question.Code);
    const answer = answers.find((answer) => answer.QuestionCode === question.Code);
    const finalAnswer = answerFromStorage?.ImageCode ? answerFromStorage : answer;

    return {
      component: UploadImage,
      code: question.Code,
      type: question.Type,
      value: ref(finalAnswer),
      isValid: ref(!!finalAnswer?.ImageCode),
      required: question.AnswerRequired,
      props: {
        description: question.Description,
        title: question.Text,
        errorText: question.AnswerRequiredValidationError,
      },
      createApiAnswer() {
        return {
          QuestionCode: this.code,
          ImageCode: this.value?.ImageCode,
          ImageName: this.value?.ImageName,
          ImageUrl: this.value?.ImageUrl,
          Type: this.type,
          error: this.props.errorText,
          isValid: this.isValid,
        };
      },
    };
  },
});
