import { createRouter, createWebHistory } from 'vue-router';
import { useUser } from '@/store/';
import { getWithExpiry } from '@/utils/localStorageExpiry';

const currentUrl = new URL(window.location);
const currentUrlParams = currentUrl.searchParams;

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import(/* webpackChunkName: "home" */ '../pages/home/Home.vue'),
  // },
  {
    path: '/events',
    alias: '/', // временный редирект на события
    name: 'events',
    component: () => import(/* webpackChunkName: "events" */ '../pages/events/Events.vue'),
  },
  {
    path: '/results',
    name: 'results',
    component: () => import(/* webpackChunkName: "results" */ '../pages/results/Results.vue'),
  },
  {
    path: '/live',
    name: 'live',
    component: () => import(/* webpackChunkName: "live" */ '../pages/live/live-list/LiveList.vue'),
    meta: {
      layout: 'live',
    },
  },
  {
    path: '/live/:eventCode/:raceCode?',
    name: 'live-detail',
    component: () => import(/* webpackChunkName: "live-detail" */ '../pages/live/live-detail/LiveDetail.vue'),
    meta: {
      layout: 'live-detail',
    },
  },
  {
    path: '/live/follow/:eventCode',
    name: 'live-follow',
    component: () => import(/* webpackChunkName: "live-follow" */ '../pages/live/live-follow/LiveFollow.vue'),
    meta: {
      layout: 'live-detail',
    },
  },
  {
    path: '/results/:eventCode/:raceCode?',
    alias: '/results/:eventCode',
    name: 'result',
    component: () => import(/* webpackChunkName: "result" */ '../pages/result/Result.vue'),
    meta: {
      layout: 'result',
    },
  },
  {
    name: 'participant',
    path: '/participant/:eventCode/:raceCode/:participantId',
    meta: {
      layout: 'result',
    },
    component: () => import(/* webpackChunkName: "participant" */ '../pages/result/memberDetails/Participant.vue'),
  },
  {
    name: 'team',
    path: '/team/:eventCode/:raceCode/:participantId',
    meta: {
      layout: 'result',
    },
    component: () => import(/* webpackChunkName: "team" */ '../pages/result/memberDetails/Team.vue'),
  },
  {
    name: 'live-participant',
    path: '/live/participant/:startNumber',
    meta: {
      layout: 'result',
    },
    component: () => import(/* webpackChunkName: "team" */ '../pages/live/live-member-details/Participant.vue'),
  },
  {
    path: '/timerkids',
    name: 'timerkids',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "timerkids" */ '../pages/events/EventsKids.vue'),
  },
  {
    path: '/:eventCode/:raceCode/register',
    name: 'register-event',
    meta: {
      layout: currentUrlParams.get('result') === 'success' ? 'default' : 'order',
    },
    component: () => import(/* webpackChunkName: "register-event" */ '../pages/register/Register.vue'),
  },
  {
    path: '/event/:code',
    name: 'event',
    children: [
      {
        name: 'event-overview',
        path: '',
        component: () => import(/* webpackChunkName: "event-overview" */ '../pages/event/Event.vue'),
      },
      {
        name: 'event-startlist',
        path: 'startlist',
        component: () => import(/* webpackChunkName: "event-startlist" */ '../pages/event/Event.vue'),
      },
    ],
  },
  {
    path: '/auth',
    name: 'auth',
    meta: {
      layout: 'auth',
    },
    children: [
      {
        path: 'sign-up',
        name: 'sign-up',
        component: () => import(/* webpackChunkName: "register" */ '../pages/auth/register/Register.vue'),
      },
      {
        path: 'sign-in',
        name: 'sign-in',
        component: () => import(/* webpackChunkName: "login" */ '../pages/auth/login/Login.vue'),
      },
      {
        path: 'reset',
        name: 'reset',
        component: () => import(/* webpackChunkName: "reset" */ '../pages/auth/reset-password/Reset.vue'),
      },
      {
        path: 'newPassword',
        name: 'newPassword',
        component: () => import(/* webpackChunkName: "new-password" */ '../pages/auth/new-password/NewPassword.vue'),
      },
      {
        path: 'setPassword',
        name: 'setPassword',
        component: () => import(/* webpackChunkName: "set-password" */ '../pages/auth/set-password/SetPassword.vue'),
      },
    ],
  },
  {
    path: '/user-account',
    name: 'user-account',
    meta: { requiresAuth: true },
    children: [
      {
        path: 'orders',
        name: 'orders',
        component: () => import(/* webpackChunkName: "orders" */ '../pages/account/orders/Orders.vue'),
      },
      {
        path: 'orders-cancel',
        name: 'orders-cancel',
        meta: {
          layout: 'order',
        },
        component: () => import(/* webpackChunkName: "orders-cancel" */ '../pages/account/orderCancel/OrderCancel.vue'),
      },
      {
        path: 'orderDetails',
        name: 'orderDetails',
        component: () => import(/* webpackChunkName: "orderDetails" */ '../pages/account/order/OrderDetails.vue'),
      },
      {
        path: 'registrations',
        name: 'registrations',
        component: () => import(/* webpackChunkName: "registrations" */ '../pages/account/registrations/Registrations.vue'),
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ '../pages/account/profile/Profile.vue'),
      },
      {
        path: 'change-password',
        name: 'change-password',
        component: () => import(/* webpackChunkName: "change-password" */ '../pages/account/changePassword/ChangePassword.vue'),
      },
      {
        path: 'teams',
        name: 'teams',
        component: () => import(/* webpackChunkName: "profile" */ '../pages/account/teams/Teams.vue'),
      }
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import(/* webpackChunkName: "not-found" */ '../pages/notFound.vue'),
  },
  {
    path: '/insurance',
    name: 'insurance',
    component: () => import(/* webpackChunkName: "insurance" */ '../pages/Insurance.vue'),
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import(/* webpackChunkName: "contacts" */ '../pages/Contacts.vue'),
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "faq" */ '../pages/faq/Faq.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  const userStore = useUser();

  const isLogged = getWithExpiry('expired-tm');

  if (!isLogged && !userStore.user.isAuthorized && to.meta.requiresAuth) {
    return {
      name: 'sign-in'
    };
  }
});

router.afterEach((to) => {
  const query = to.query;
  const scrollToTop = query?.scrollToTop;

  if (scrollToTop) {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }
});

export default router;
