import { defineStore } from 'pinia';
import { getCurrentUser, getProfileUserInfo } from '@/api/currentUser';
import { setWithExpiry, getWithExpiry } from '@/utils/localStorageExpiry';

export const useUser = defineStore('user', {
  state: () => ({
    user: {
      isAuthorized: false,
      userProfileLoading: true,
      updatedProfile: false,
      RaceClub: null,
      OccupationCode: null,
    },
  }),

  getters: {
    avatarIsExist(state) {
      return Boolean(state.user.avatarLargeUrl);
    },
  },

  actions: {
    async getUser() {
      try {
        const userCurrent = await getCurrentUser();

        if (userCurrent.isAuthorized) {
          const prevExpiredSecondsPerWeek = getWithExpiry('expired-tm');

          if (!prevExpiredSecondsPerWeek) {
            const secondsPerWeek = 7 * 24 * 60 * 60;

            setWithExpiry('expired-tm', secondsPerWeek);
          }

          const userInfo = await getProfileUserInfo();
          this.user = { ...this.user, ...userCurrent, ...userInfo, userProfileLoading: false };
        }

        return this.user;
      } catch (error) {
        console.error(error);
      }
    },
  },
});
