import { defineStore } from 'pinia';
import {
  getProducts,
  addProductToCart,
  removeProductFromCart,
  setProductCount,
  getPolicyRequest,
  saveProducts,
} from '@/api/register';
import getQuestionsDict from '@/pages/register/utils/getQuestionsDict.js';

export const useProductsStore = defineStore('products', {
  state: () => ({
    products: [],
    cart: null,
    registerStore: null,
    lastProductAdded: null,
    apiProductsLoaded: false,
    policyInfo: null,
    insuranceProduct: null,
    skipOptions: false,
  }),
  getters: {
    productsInCartAmount() {
      return this.cartItems.length;
    },
    cartItems() {
      if (!this.registerStore || !this.cart?.Items) return [];
      return this.cart.Items.filter((item) => !item.IsRequired && !item.IsInsurance) ?? [];
    },
    stockByHash() {
      const allArticles = this.products.reduce((acc, product) => {
        return [...acc, ...(product.fullInfo.Articles || [])];
      }, []);

      return allArticles.reduce((acc, article) => {
        let hash = article.ProductId;

        if (article.Parameters && article.Parameters.length > 0) {
          hash = article.Parameters.reduce((acc, param) => acc + param.QuestionCode + param.OptionCode, article.ProductId);
        }

        acc[hash] = article;

        return acc;
      }, {});
    },
    productsInCartByHashes() {
      return this.cartItems
        .reduce((itemAcc, item) => {
          let hash = item.Id;

          if (item.Answers && item.Answers.length > 0) {
            hash = item.Answers.reduce((answerAcc, answer) => {
              return answerAcc + answer.QuestionCode + answer.SelectedOptionsCodes[0];
            }, item.ProductId);
          }

          itemAcc[hash] = item;

          return itemAcc;
        }, {});
    },
    productsById() {
      return this.products.reduce((acc, item) => {
        acc[item.id] = item;

        return acc;
      }, {});
    },
    productsInCartById() {
      return this.cartItems.reduce((acc, item) => {
        acc[item.ProductId] = item;

        return acc;
      }, {});
    },
    productsInCartAmountsById() {
      return this.cartItems.reduce((acc, item) => {
        acc[item.ProductId] = (acc[item.ProductId] | 0) + item.Count;

        return acc;
      }, {});
    },
    productsInCartSum() {
      return this.cartItems.reduce((acc, item) => acc + (item.Price.Value * item.ProductCount), 0);
    },
    insuranceInfo() {
      if (!this.registerStore) return;

      const insuranceInfo = {
        InsuranceProviderKey: 'Vsk',
        RegistrationId: this.registerStore.registrationId,
        DisciplinesCodes: ['run'],
        Period: 'Day',
        AdditionalOptions: [],
        StartDate: this.registerStore.raceDateISO,
      };

      return insuranceInfo;
    },
  },
  actions: {
    async saveApiProducts() {
      const { error } = await saveProducts(this.registerStore.registrationId, this.cartItems);

      if (error) {
        console.error(error);
        return;
      }
    },
    async getInsurancePolicy() {
      const { error, data } = await getPolicyRequest(this.insuranceInfo);

      if (error) {
        console.error(error);
        return;
      }

      this.policyInfo = data;
      return data;
    },
    setLastProductAdded(product) {
      this.lastProductAdded = product;
    },
    setDonationPrice(productId, newPrice) {
      this.productsById[productId].price = newPrice;
    },
    async setProductAmount(cartItemId, count) {
      const { error, data } = await setProductCount(this.registerStore.registrationId, cartItemId, count);

      if (error || !data.Success) {
        console.error(error);
        return;
      }
    },
    async removeFromCart(cartId) {
      const { error, data } = await removeProductFromCart(this.registerStore.registrationId, cartId);

      if (error || !data.Success) {
        console.error(error);
        return;
      }
    },
    async addInsuranceToCart() {
      const info = {
        InsuranceProviderKey: 'Vsk',
        InsurancePolicyId: this.policyInfo.InsurancePolicyId,
        RegistrationId: this.registerStore.registrationId,
        ProductCount: 1,
        Answers: [],
        ProductId: this.insuranceProduct.Id,
        Price: this.insuranceProduct.Price,
        ParametersHash: '',
      };
      const { error, data } = await addProductToCart(info);

      if (error || !data.Success) {
        console.error(error);
        return;
      }

      this.insuranceProduct.CartItemId = data.CartItemId;
    },
    async addToCart(productId) {
      const product = this.productsById[productId];

      let Answers = [];

      if (product.questionComponents) {
        Answers = product.questionComponents.map((question) => ({
          SelectedOptionsCodes: [question.value.id],
          QuestionCode: question.code,
          isValid: true,
          Type: question.type,
          error: question.props.errorText,
        }));
      }

      const info = {
        RegistrationId: this.registerStore.registrationId,
        ProductCount: 1,
        Answers,
        ProductId: product.id,
        Price: product.price,
        ParametersHash: '',
      };
      const { error, data } = await addProductToCart(info);

      if (error || !data.Success) {
        console.error(error);
        return;
      }

      return product;
    },
    async initProductStore(registerStore) {
      const { error, data } = await getProducts(registerStore.registrationId);

      if (error || !data.Success) {
        console.error(error);
        this.skipOptions = true;
        return;
      }

      this.insuranceProduct = data.Products && data.Products.find((product) => product.SellByCheckbox && product.IsService);

      const formattedProducts = data.Products
        .filter((product) => !product.IsService)
        .map((product) => this.createProduct(product));

      this.products = formattedProducts;
      this.registerStore = registerStore;

      const raceCart = data.Cart.Items.find((cartItem) => {
        return cartItem.EventCode === registerStore.eventCode && cartItem.RaceCode === registerStore.raceCode;
      });

      const raceCartItems = (raceCart?.Items || []);

      if (this.registerStore.isRelay) {
        this.cart = raceCartItems.find((cartItem) => {
          return cartItem.Id === registerStore.registrationId;
        });
      } else {
        this.cart = raceCart;
      }

      this.apiProductsLoaded = true;

      return formattedProducts;
    },
    createProduct(apiProduct) {
      let questionComponents = null;

      if (apiProduct.Questionnaire) {
        const questionsDict = getQuestionsDict();

        questionComponents = apiProduct.Questionnaire.Groups[0].Questions
          .filter((question) => question.Type === 'DropDownChoice')
          .map((question) => {
            if (questionsDict[question.Type]) {
              return questionsDict[question.Type](question);
            }
          }).filter(Boolean);
      }

      return {
        title: apiProduct.Title,
        id: apiProduct.Id,
        type: apiProduct.PriceRule.MaxPrice ? 'donation' : 'product',
        price: apiProduct.Price,
        priceText: String(apiProduct.Price),
        oldPrice: null,
        oldPriceText: null,
        priceRule: apiProduct.PriceRule,
        image: apiProduct.ImageUrl,
        description: apiProduct.Description,
        questionComponents,
        showSidebar: false,
        fullInfo: apiProduct,
      };
    },
  },
});
