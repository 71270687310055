import { defineStore } from 'pinia';
import i18n from '@/i18n.js';
import { getTenantInfo } from '@/api/tenant.js';
import { getLiveCount } from '@/api/live';

export const useVariablesStore = defineStore('variables', {
  state: () => ({
    apiDevUrl: '',
    apiProdUrl: 'https://timerman-backend.rr-dev.ru',
    tenant: null,
    _liveCount: 0,
    _liveUrl: '',
  }),
  getters: {
    tenantInfo: (state) => state.tenant,
    supportEmail: () => 'info@timerman.org',
    apiUrl: (state) => {
      return process.env.NODE_ENV === 'development' ? state.apiDevUrl : state.apiProdUrl;
    },
    cdnUrl: () => {
      return 'https://tjju0815gb.a.trbcdn.net/cdn/futeyiwa27/timerman';
    },
    lang: () => i18n.global.locale.value,
    liveCount: (state) => state._liveCount,
    liveUrl: (state) => state._liveUrl,
  },
  actions: {
    async setLiveCount() {
      const { data, error } = await getLiveCount();

      if (error) return;

      this._liveCount = data;
    },
    async setTenantInfo() {
      const { error, data } = await getTenantInfo();

      if (error) {
        console.error(error);
        return;
      }

      this.tenant = data;
    },

    setLiveUrl(liveUrl) {
      this._liveUrl = liveUrl;
    }
  }
});
