import responseObject from '@/utils/responseObject.js';

export const savePayment = (registrationId, paymentType) => {
  return fetch('/Register/ApiSavePay', {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      RegistrationId: registrationId,
      PaymentType: paymentType,
    }),
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return responseObject(data);
    })
    .catch((error) => responseObject(null, error));
};
