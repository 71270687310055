import apiWrapper from '@/utils/apiWrapper';
import i18Instance from '@/i18n';

const language = i18Instance.global.locale.value;

export const getResults = (raceId, chronoId, page, pageSize, filters = {}, liveUrl) => {
  return apiWrapper.post(
    `${liveUrl}/LiveServices/GetResults`,
    {
      language,
      showJson: true,
      highlightedParticipantOrTeamId: null,
      isMobile: false,
      raceId,
      chronoId,
      page,
      pageSize,
      ...filters,
    },
    null,
    'same-origin'
  );
};
