const metricCode = 42001724;

/**
 * @param {string} target
 * @param {Object} params
 */
export default (target, params) => {
  if (window.ym) {
    window.ym(metricCode, target, params);
  }
};

export const reachGoal = (eventId) => {
  if (window.ym) {
    window.ym(metricCode, 'reachGoal', eventId);
  }
};
