import { useLocalStorage } from '@vueuse/core';

const userStorageInfo = useLocalStorage('tm-user-reg', {});

const clearUserStorage = () => {
  userStorageInfo.value = {};
};

export {
  userStorageInfo,
  clearUserStorage,
};
