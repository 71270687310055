import responseObject from '@/utils/responseObject.js';

/**
 *
 * @param {import('./types').RegInfo} regInfo
 * @returns {Object}
 */
export const saveRegistration = (regInfo) => {
  return fetch('/Register/ApiSaveRegistration', {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(regInfo),
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return responseObject(data);
    })
    .catch((error) => responseObject(null, error));
};
