import responseObject from '@/utils/responseObject.js';

export const getProducts = (registrationId) => {
  return fetch(`/Register/ApiProducts?registrationId=${registrationId}`)
    .then((response) => response.json())
    .then((data) => {
      return responseObject(data);
    })
    .catch((error) => responseObject(null, error));
};
