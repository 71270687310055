/**
 * Объект с ошибкой и данными
 * @typedef {object} ResponseObjectType
 * @property {*} data
 * @property {object|null} error
 * @property {string} [error.message]
 */

/**
 * @returns {ResponseObjectType}
 */
const responseObject = (data = null, error = null) => ({ data, error });

export default responseObject;
